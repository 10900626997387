var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._t("header",null,{"onChangeFilter":_vm.onChangeFilter})],2),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.itemsSize,"footer-props":{'items-per-page-options': [5, 10, 20, 50]},"loading":_vm.isLoading || _vm.filterLoading,"disable-pagination":_vm.isLoading || _vm.filterLoading,"disable-filtering":_vm.isLoading || _vm.filterLoading,"disable-sort":_vm.isLoading || _vm.filterLoading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers.slice().filter(function (h){ return h.custom; })),function(header){return {key:_vm.getTmpItemName(header),fn:function(ref){
var item = ref.item;
return [_vm._t(header.value,null,{"item":item})]}}}),{key:"item.ERRORE",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":item.ERRORE === 'NONE' ? (item.IS_INVISIBILE ? 'warning' : 'success') : 'error'}},on),[_vm._v(" fa fa-"+_vm._s(item.ERRORE === 'NONE' ? 'check-circle' : 'times-circle')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getErroreTooltip(item)))])])]}},{key:"header.actions",fn:function(){return [(_vm.canCreate)?_c('button-tooltip',{attrs:{"small":"","icon":"","icon-name":"fa-plus","color":"success","disabled":_vm.isLoading || _vm.filterLoading,"to":'/secure/' + (!_vm.routesName ? _vm.modelsName : _vm.routesName) + '/form',"exact":"","tooltip":'Click per aggiungere ' + _vm.newMsg}}):_vm._e()]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"icon":"","icon-name":"fa-chevron-circle-right","color":"primary","disabled":_vm.isLoading || _vm.filterLoading,"to":'/secure/' + (!_vm.routesName ? _vm.modelsName : _vm.routesName) + '/scheda' + '?id=' + item.ID_REC,"exact":"","tooltip":"Visualizza"}})]}}],null,true)}),_vm._t("dialog")],2)}
var staticRenderFns = []

export { render, staticRenderFns }