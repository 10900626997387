<template>
    <h-row justify="center" class="pb-6 mb-6">
        <h-col class="py-2 mb-4">
            <h-row :class="'px-2 py-' + (noPadding ? '0' : '4')" align="center">

                <h-col class="py-0" cols="auto">
                    <h1>
                        {{
                            (!sezioneName ? modelsName : sezioneName).replace(
                                /\w\S*/g,
                                txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                            )
                        }}
                    </h1>
                </h-col>

                <h-col v-if="!!options.page" class="py-0" cols="auto">
                    <button-tooltip large
                                    icon
                                    icon-name="fa-sync-alt"
                                    color="primary"
                                    :disabled="isGlobalLoading"
                                    tooltip="Click per aggiornare la tabella"
                                    @click.stop="loadResources"
                    ></button-tooltip>
                </h-col>

                <slot name="header"></slot>

            </h-row>

            <slot name="table" :is-loading="isLoading" :onChangeOptions="onChangeOptions"></slot>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';

    export default {
        name: 'BaseSezione',
        components: {HRow, HCol, ButtonTooltip},
        props: {modelsName: String, sezioneName: String, path: String, initOptions: Object, noPadding: Boolean},
        data: (_this) => ({
            isLoading: false,
            apiTimer: null,
            options: {
                idCittaFk: /^\d+$/.test(_this.$route.query.ctt) ? Number(_this.$route.query.ctt) : null,
                idProvinciaFk: /^\d+$/.test(_this.$route.query.prv) ? Number(_this.$route.query.prv) : null,
                idClienteFk: /^\d+$/.test(_this.$route.query.cln) ? Number(_this.$route.query.cln) : null,
                idEventoFk: /^\d+$/.test(_this.$route.query.evt) ? Number(_this.$route.query.evt) : null,
                idLinguaFk: /^\d+$/.test(_this.$route.query.lng) ? Number(_this.$route.query.lng) : null,
                idRuoloFk: /^\d+$/.test(_this.$route.query.rls) ? Number(_this.$route.query.rls) : null,
                idTipologiaFk: /^\d+$/.test(_this.$route.query.tpl) ? Number(_this.$route.query.tpl) : null,
                idUtenteFk: /^\d+$/.test(_this.$route.query.usr) ? Number(_this.$route.query.usr) : null,
                idWorkspaceFk: /^\d+$/.test(_this.$route.query.wrk) ? Number(_this.$route.query.wrk) : null,
                filter: _this.$route.query.flt,
                ..._this.initOptions
            }
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            }
        },
        methods: {
            loadResources() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //GET del modello
                        _this.$store.dispatch('defaultRequest', {
                            api: 'GET_BASE',
                            paylod: {modelsName: _this.modelsName, path: _this.path, options: _this.options},
                            doResponse: () => {
                            },
                            doClose: () => _this.isLoading = false
                        });
                    }
                }

                this.isLoading = true;
                this.$store.commit('SET_' + this.modelsName.toUpperCase(), []);
                this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                doRequest(this);
            },
            onChangeOptions(options) {
                this.options = {
                    ...this.options,
                    ...options
                };
                this.loadResources();
            }
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
            this.$store.commit('SET_' + this.modelsName.toUpperCase(), []);
        }
    }
</script>

<style scoped>

</style>
