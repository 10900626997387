<template>
    <base-text-field v-model.trim="filter"
                     label="Search"
                     placeholder="Digita per iniziare la ricerca"
                     clearable
                     :append-icon="exact ? ( isExact ? 'fas fa-check-square' : 'far fa-check-square' ) : null"
                     @appendClick="isExact = !isExact"
    ></base-text-field>
</template>

<script>
    import BaseTextField from '@/components/aaaGenerics/form/BaseTextField';

    export default {
        name: 'BaseTableHeaderFilter',
        components: {BaseTextField},
        props: {exact: Boolean},
        data: (_this) => ({
            filter: _this.$route.query.flt,
            filterLoading: false,
            timerFilter: null,
            isExact: false
        }),
        watch: {
            filter: function () {
                clearTimeout(this.timerFilter);
                this.filterLoading = true;

                this.timerFilter = setTimeout(() => {
                    this.filterLoading = false;
                    this.$emit('changeOptions', {filter: this.filter});
                }, 1000);
            },
            filterLoading: function () {
                this.$emit('changeFilter', this.filterLoading);
            },
            isExact: function () {
                this.$emit('changeOptions', {isExact: this.isExact});
            }
        },
        beforeDestroy() {
            window.clearTimeout(this.timerFilter);
        }
    }
</script>

<style scoped>

</style>

