<template>
    <v-card>
        <v-card-title>
            <slot name="header" :onChangeFilter="onChangeFilter"></slot>
        </v-card-title>

        <v-data-table dense
                      :headers="headers"
                      :items="items"
                      :options.sync="options"
                      :server-items-length="itemsSize"
                      :footer-props="{'items-per-page-options': [5, 10, 20, 50]}"
                      :loading="isLoading || filterLoading"
                      :disable-pagination="isLoading || filterLoading"
                      :disable-filtering="isLoading || filterLoading"
                      :disable-sort="isLoading || filterLoading"
        >

            <template v-for="header in headers.slice().filter(h=>h.custom)" v-slot:[getTmpItemName(header)]="{ item }">
                <slot :name="header.value" :item="item"></slot>
            </template>

            <template #item.ERRORE="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on"
                                small
                                :color="item.ERRORE === 'NONE' ? (item.IS_INVISIBILE ? 'warning' : 'success') : 'error'"
                        >
                            fa fa-{{ item.ERRORE === 'NONE' ? 'check-circle' : 'times-circle' }}
                        </v-icon>
                    </template>
                    <span>{{ getErroreTooltip(item) }}</span>
                </v-tooltip>
            </template>

            <template v-slot:header.actions>
                <button-tooltip v-if="canCreate"
                                small
                                icon
                                icon-name="fa-plus"
                                color="success"
                                :disabled="isLoading || filterLoading"
                                :to="'/secure/' + (!routesName ? modelsName : routesName) + '/form'"
                                exact
                                :tooltip="'Click per aggiungere ' + newMsg"
                ></button-tooltip>
            </template>

            <template v-slot:item.actions="{ item }">
                <button-tooltip icon
                                icon-name="fa-chevron-circle-right"
                                color="primary"
                                :disabled="isLoading || filterLoading"
                                :to="'/secure/' + (!routesName ? modelsName : routesName) + '/scheda' + '?id=' + item.ID_REC"
                                exact
                                tooltip="Visualizza"
                ></button-tooltip>
            </template>
        </v-data-table>

        <slot name="dialog"></slot>
    </v-card>
</template>

<script>
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';

    export default {
        name: 'BaseTable',
        components: {ButtonTooltip},
        props: {
            modelsName: String,
            routesName: String,
            newMsg: String,
            isLoading: Boolean,
            headers: Array,
            canCreate: Boolean
        },
        data: () => ({
            options: {
                itemsPerPage: 10,
            },
            filterLoading: false
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            },
            items: function () {
                return this.$store.state.apiModule[this.modelsName];
            },
            itemsSize: function () {
                return this.$store.state.apiModule[this.modelsName + 'Size'];
            }
        },
        watch: {
            options: function () {
                this.$emit('changeOptions', this.options);
            }
        },
        methods: {
            onChangeFilter(filterLoading) {
                this.filterLoading = filterLoading;
            },
            getTmpItemName(header) {
                return 'item.' + header.value;
            },
            getErroreTooltip(item) {

                switch (item.ERRORE) {
                    case 'NONE':
                        return 'Attivo e visibile';
                    case 'SELF':
                        return 'Disattivato manualmente';
                    case 'DELETED':
                        return 'Cancellato';
                    case 'WORKSPACE_OFF':
                        return 'Il workspace NON risulta attivo';
                    case 'CLIENTE_OFF':
                        return 'Il cliente NON risulta attivo';
                    case 'TIPOLOGIA_OFF':
                        return 'La tipologia NON risulta attiva';
                    case 'CITTA_OFF':
                        return 'La città NON risulta attiva';
                    case 'NO_CLC':
                        return 'Manca un contratto definitivo non scaduto';
                    case 'PREVENTIVO':
                        return 'Contratto NON risulta definitivo';
                    case 'NO_STARTED':
                        return 'Troppo presto, deve ancora iniziare';
                    case 'EXPIRED':
                        return 'Troppo tardi, scaduto da tempo';
                    case 'NO_TEXT_DEFAULT':
                        return 'Mancano i testi della lingua di default';
                    case 'LAVORAZIONE':
                        return 'Galleria NON risulta definitiva, ma in lavorazione';
                    case 'COVER_LNG':
                        return 'La cover NON può essere associata a una lingua particolare';
                    case 'START_MUST_BE_EMPTY':
                        return 'La data di inizio deve essere vuota';
                    case 'END_MUST_BE_EMPTY':
                        return 'La data di fine deve essere vuota';
                    case 'START_MISSED':
                        return 'Mancano la data di inizio';
                    case 'END_MISSED':
                        return 'Mancano la data di fine';
                    case 'NO_COVER':
                        return 'Manca la cover';
                    case 'NO_GPX':
                        return 'Manca il file GPX';
                    case 'MULTIPLE_COVER':
                        return 'Risultano troppe cover, ne serve solo 1';
                    case 'SOME_GALLERIA_ERRATA':
                        return 'Sono presenti delle gallerie errate';
                    case 'SOME_LINK_ERRATO':
                        return 'Sono presenti dei link errati';
                    case 'NO_CLC_TAGLIA':
                        return 'Il cliente NON possiede abbastanza eventi per la taglia ' + item.TAGLIA;
                    default:
                        return 'STATUS NON GESTITO [' + item.ERRORE + ']';
                }

            }
        }
    }
</script>

<style scoped>

</style>
